import React, { useContext } from "react";
import {
  Card,
  Container,
  Group,
  ThemeIcon,
  Title,
  Alert,
  Text,
  Box,
  Button,
} from "@mantine/core";
import { IconFileExport, IconAlertCircle } from "@tabler/icons-react";
import { useExportTickets } from "../../api/tickets";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";

function ExportTickets() {
  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);
  const currentEventId = currentWorkspace.eventId;

  const { mutate: exportMutate, isLoading: isExporting } = useExportTickets();

  const handleExport = () => {
    exportMutate({ eventId: currentEventId });
  };

  return (
    <Container size="lg" mt="md">
      <Card shadow="sm" p="lg" radius="md" withBorder>
        <Card.Section withBorder inheritPadding py="xs" bg="blue.0">
          <Group>
            <ThemeIcon size="lg" variant="light" color="blue">
              <IconFileExport size={20} />
            </ThemeIcon>
            <Title order={4}>Export Tickets</Title>
          </Group>
        </Card.Section>

        <Alert
          icon={<IconAlertCircle size={16} />}
          title="Export Options"
          color="blue"
          variant="light"
          mt="md"
        >
          <Text size="sm">
            This will export all tickets to a CSV file. It will export with
            visible columns that are currently set in the table settings.
          </Text>
        </Alert>

        <Box>
          <Group mt="xl" position="center">
            <Button
              leftSection={<IconFileExport size={16} />}
              onClick={handleExport}
              color="blue"
              loading={isExporting}
              size="md"
            >
              Generate Export
            </Button>
          </Group>
        </Box>
      </Card>
    </Container>
  );
}

export default ExportTickets;
