import React, { useContext, useState } from "react";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import {
  useGetAllRegistrations,
  useGetRegistration,
  useUpdateRegistration,
} from "../../api/registrations";
import { DataTable } from "../DataTable";
import RegistrationStatusBadge from "./RegistrationStatusBadge";
import dayjs from "dayjs";
import {
  Box,
  Text,
  Flex,
  Group,
  ActionIcon,
  Tooltip,
  Select,
  TextInput,
} from "@mantine/core";
import {
  IconCheck,
  IconChevronRight,
  IconDotsVertical,
  IconSearch,
  IconX,
  IconXboxX,
} from "@tabler/icons-react";
import clsx from "clsx";
import classes from "./registrations.module.css";
import approveRegistrationModal from "./Modals/ApproveModal";
import rejectRegistrationModal from "./Modals/RejectModal";
import TicketCard from "./TicketCard";
import { useDebouncedValue } from "@mantine/hooks";
import { useSearchParams } from "react-router-dom";
import AdditionalFieldDisplay from "./AdditionalFieldDsiplay";

function RegistrationsTable() {
  const { user } = useContext(AuthContext);
  const { eventId } = getCurrentWorkspace(user);

  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize states from URL params or use defaults
  const [pagination, setPagination] = useState(() => {
    return {
      page: parseInt(searchParams.get("page") || "1", 10),
      limit: parseInt(searchParams.get("limit") || "10", 10),
    };
  });

  const [sorting, setSorting] = useState(() => {
    try {
      const sortParams = searchParams.get("sort");
      return sortParams
        ? JSON.parse(sortParams)
        : {
            columnAccessor: "createdAt",
            direction: "desc",
          };
    } catch (e) {
      return {
        columnAccessor: "createdAt",
        direction: "desc",
      };
    }
  });

  const [filter, setFilter] = useState(() => {
    try {
      const filterParams = searchParams.get("filter");
      return filterParams ? JSON.parse(filterParams) : null;
    } catch (e) {
      return null;
    }
  });

  const [debouncedFilter] = useDebouncedValue(filter, 200);

  const { data, isPending } = useGetAllRegistrations({
    eventId,
    ...pagination,
    sort: JSON.stringify(sorting),
    filter: debouncedFilter,
  });

  const { mutateAsync } = useUpdateRegistration();

  const [expandedRow, setExpandedRow] = useState([]);

  const handlePageChange = (page) => {
    setPagination({ ...pagination, page });
    setSearchParams((searchParams) => {
      searchParams.set("page", page.toString());
      return searchParams;
    });
  };

  const handleSortChange = (sort) => {
    setSorting(sort);
    setSearchParams((searchParams) => {
      if (!sort) {
        searchParams.delete("sort");
        return searchParams;
      }
      searchParams.set("sort", JSON.stringify(sort));
      return searchParams;
    });
  };

  const handleFilterChange = (filter) => {
    setFilter(filter);
    setSearchParams((searchParams) => {
      if (!filter) {
        searchParams.delete("filter");
        return searchParams;
      }
      searchParams.set("filter", JSON.stringify(filter));
      return searchParams;
    });
  };

  return (
    <DataTable
      records={data?.data}
      pagination={pagination}
      onPageChange={handlePageChange}
      page={pagination.page}
      recordsPerPage={pagination.limit}
      totalRecords={data?.pagination.total}
      onSortStatusChange={handleSortChange}
      sortStatus={sorting}
      borderRadius="md"
      verticalSpacing="md"
      rowExpansion={{
        allowMultiple: true,
        expanded: {
          recordIds: expandedRow,
        },
        content: ({ record }) => (
          <RegistrationTicketsTable registrationId={record.id} />
        ),
      }}
      columns={[
        {
          accessor: "companyName",
          sortable: true,
          render: ({ companyName, id }) => (
            <Box component="span" ml={8}>
              <ActionIcon
                variant="light"
                color="gray"
                onClick={() => {
                  setExpandedRow((rows) =>
                    rows.includes(id)
                      ? rows.filter((row) => row !== id)
                      : [...rows, id]
                  );
                }}
                mr="sm"
              >
                <IconChevronRight
                  className={clsx(classes.icon, classes.expandIcon, {
                    [classes.expandIconRotated]: expandedRow.includes(id),
                  })}
                />
              </ActionIcon>
              <span>{companyName}</span>
            </Box>
          ),
          filter: (
            <FilterSearch
              query={filter}
              setQuery={handleFilterChange}
              columnName="companyName"
            />
          ),
          filtering: filter?.companyName,
        },
        {
          accessor: "ticketCount",
          sortable: true,
          width: "0%",
          title: "No. of Tickets",
        },
        {
          accessor: "email",
          sortable: true,
          filter: (
            <FilterSearch
              query={filter}
              setQuery={handleFilterChange}
              columnName="email"
            />
          ),
          filtering: filter?.email,
        },
        {
          accessor: "vatNumber",
          title: "VAT Number",
          sortable: true,
          filter: (
            <FilterSearch
              query={filter}
              setQuery={handleFilterChange}
              columnName="vatNumber"
            />
          ),
          filtering: filter?.vatNumber,
        },
        {
          accessor: "status",
          sortable: true,
          render: ({ status }) => <RegistrationStatusBadge status={status} />,
          filter: <StatusFilter query={filter} setQuery={handleFilterChange} />,
          filtering: filter?.status,
        },
        {
          accessor: "createdAt",
          title: "Created At",
          sortable: true,
          render: ({ createdAt }) =>
            dayjs(createdAt).locale("en").format("DD MMM YYYY - HH:mm"),
        },
        {
          accessor: "actions",
          title: <Box mr={6}>Actions</Box>,
          textAlign: "right",
          render: (record) => {
            return (
              <Group gap="sm" justify="right" wrap="nowrap">
                {record.status === "pending" && (
                  <>
                    <Tooltip label="Approve registration">
                      <ActionIcon
                        size="lg"
                        variant="light"
                        color="green"
                        onClick={(e) => {
                          e.stopPropagation();
                          approveRegistrationModal({
                            registration: record,
                            mutateAsync,
                          });
                        }}
                      >
                        <IconCheck size={20} />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Reject registration">
                      <ActionIcon
                        size="lg"
                        variant="light"
                        color="red"
                        onClick={(e) => {
                          e.stopPropagation();
                          rejectRegistrationModal({
                            registration: record,
                            mutateAsync,
                          });
                        }}
                      >
                        <IconXboxX size={20} />
                      </ActionIcon>
                    </Tooltip>
                  </>
                )}
                <Tooltip label="More informations">
                  <ActionIcon
                    size="lg"
                    variant="light"
                    color="blue"
                    onClick={(e) => {
                      e.stopPropagation();
                      setExpandedRow((rows) =>
                        rows.includes(record.id)
                          ? rows.filter((row) => row !== record.id)
                          : [...rows, record.id]
                      );
                    }}
                  >
                    <IconDotsVertical size={20} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            );
          },
        },
      ]}
      fetching={isPending}
    />
  );
}

const RegistrationTicketsTable = ({ registrationId }) => {
  const { data, isPending } = useGetRegistration({ registrationId });

  if (isPending) {
    return <Text>Loading...</Text>;
  }

  return (
    <Flex direction="column" py="sm" px="md">
      {data?.additionalFields && (
        <>
          <Text fw={700} size="md">
            Additional information
          </Text>
          <Flex direction="row" gap="md" wrap="wrap" pb="sm">
            {Object.entries(data?.additionalFields).map(([key, value]) => (
              <AdditionalFieldDisplay key={key} fieldKey={key} value={value} />
            ))}
          </Flex>
        </>
      )}

      <Text fw={700} size="md">
        Tickets
      </Text>
      <Flex direction="row" gap="md" wrap="wrap">
        {data?.tickets.map((ticket) => (
          <TicketCard key={ticket.id} ticket={ticket} />
        ))}
      </Flex>
    </Flex>
  );
};

function FilterSearch({ query, setQuery, columnName }) {
  // Transform camel case to readable text
  const camelToReadableColumnName = columnName
    .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
    .toLowerCase();
  return (
    <TextInput
      label="Tickets"
      description={`Show tickets whose ${camelToReadableColumnName} includes the specified text`}
      placeholder="Search tickets..."
      leftSection={<IconSearch size={16} />}
      rightSection={
        <ActionIcon
          size="sm"
          variant="transparent"
          c="dimmed"
          onClick={() => setQuery(null)}
        >
          <IconX size={14} />
        </ActionIcon>
      }
      value={query?.[columnName] || ""}
      onChange={(e) => {
        if (e.currentTarget.value === "") {
          setQuery(null);
          return;
        }
        setQuery({ [columnName]: e.currentTarget.value });
      }}
    />
  );
}

function StatusFilter({ query, setQuery }) {
  return (
    <Select
      label="Status"
      description="Filter tickets by status"
      placeholder="Filter by status"
      data={[
        { value: "pending", label: "Pending" },
        { value: "approved", label: "Approved" },
        { value: "rejected", label: "Rejected" },
      ]}
      value={query?.status}
      onChange={(value) => {
        setQuery({ status: value });
      }}
      onClear={() => setQuery(null)}
      clearable
    />
  );
}

export default RegistrationsTable;
