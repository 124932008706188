import { Card, Text, Title, Group } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";

function FeatureNotAvailable() {
  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Group align="center" spacing="md">
        <IconInfoCircle size={32} color="#FDC300" />
        <Title order={3}>Feature Not Available</Title>
      </Group>
      <Text mt="md">
        Sorry, this feature is not available for this event at the moment.
        Please check back later or contact support for more details.
      </Text>
    </Card>
  );
}

export default FeatureNotAvailable;
