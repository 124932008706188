import { useContext, useMemo } from "react";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { useState } from "react";
import { useGetAllAnnouncements } from "../../api/announcements";
import { useGetTagColors } from "../../api/tag";
import AnnouncementsTable from "./AnnouncementsTable";
import { useSetActionButtons } from "../../services/ActionButtonsContext";
import { IconPlus } from "@tabler/icons-react";
import { Box } from "@mantine/core";

function Announcements() {
  const actions = useMemo(
    () => [
      {
        label: "Create",
        icon: <IconPlus />,
        navigate: "./announcements/create",
      },
    ],
    []
  );

  useSetActionButtons(actions);

  const { user } = useContext(AuthContext);

  const currentWorkspace = getCurrentWorkspace(user);

  const eventId = currentWorkspace.eventId;

  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const { data } = useGetAllAnnouncements({
    eventId,
    getAll: true,
    type: "info,warning",
    ...pagination,
  });

  const { data: tagColors } = useGetTagColors(eventId);

  return (
    <Box py="sm">
      <AnnouncementsTable
        data={data}
        pagination={{ data: pagination, setPagination }}
        tagColors={tagColors}
      />
    </Box>
  );
}

export default Announcements;
