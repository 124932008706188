import { notifications } from "@mantine/notifications";
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { callApi } from "./api";
import { appendSearchParams } from "./helper";
import { createWebStorageClient } from "@congrs/file-upload";
import posthog from "posthog-js";

export const queryClient = new QueryClient();

/**
 * @typedef {Object} FetchOptions
 * @property {string} url - The URL for the query.
 * @property {string} method - The HTTP method for the query.
 * @property {Object} body - The body of the request.
 * @property {Object} params - The query parameters.
 */

/**
 * Custom hook for making authenticated API queries using react-query.
 * @param {Object} config - The configuration for the query.
 * @param {import("@tanstack/react-query").UseQueryOptions} config.queryOptions - The configuration options for the query.
 * @param {FetchOptions} config.fetchOptions - The fetch options for the query.
 * @returns {import("@tanstack/react-query").UseQueryResult} - The result of the query.
 */
export const useApiQuery = (config) => {
  const { fetchOptions } = config;

  if (fetchOptions.params) {
    fetchOptions.url = appendSearchParams(
      fetchOptions.url,
      fetchOptions.params
    );
  }

  return useQuery({
    ...config.queryOptions,
    queryFn: async () => {
      const token = localStorage.getItem("token");

      return callApi({
        url: fetchOptions.url,
        method: fetchOptions.method,
        body: fetchOptions.body,
        token,
      });
    },
  });
};

/**
 * Custom hook for making authenticated API mutations using react-query.
 * @param {Object} config - The configuration for the mutation.
 * @param {import("@tanstack/react-query").UseMutationOptions} config.mutationOptions - The configuration options for the mutation.
 * @param {FetchOptions} config.fetchOptions - The fetch options for the mutation.
 * @param {String} config.invalidateQueryKey - The query key to invalidate after the mutation success.
 * @param {Boolean} config.hideNotification - Whether to hide the notification after the mutation.
 * @param {import("@mantine/notifications").NotificationsProps} config.successMessage - The success message to show after the mutation.
 * @returns {import("@tanstack/react-query").UseMutationResult} - The result of the mutation.
 */

export const useApiMutation = (config) => {
  const { fetchOptions } = config;

  if (fetchOptions.params) {
    fetchOptions.url = appendSearchParams(
      fetchOptions.url,
      fetchOptions.params
    );
  }

  return useMutation({
    ...config.mutationOptions,
    mutationFn: async (body) => {
      const token = localStorage.getItem("token");

      return callApi({
        url: fetchOptions.url,
        method: fetchOptions.method,
        body,
        token,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(config.invalidateQueryKey);

      if (!config.hideNotification) {
        const successMessage = {
          title: "Operation successful",
          color: "green",
          ...config.successMessage,
        };

        notifications.show(successMessage);
      }

      // Capture mutation event in PostHog with page and environment
      const action = fetchOptions.url
        .split("/")[1]
        .concat("_", fetchOptions.method.toLowerCase());
      posthog?.capture(action, {
        page: fetchOptions.url,
        env: process.env.CLOUD_ENV,
      });
    },
    onError: (error) => {
      const isSqlError = error.code && error.code.startsWith("ER_"); // MySQL errors usually start with 'ER_'

      const errorMessage = {
        title: "Error",
        color: "red",
        message: isSqlError
          ? config.errorMessages?.[error.code] || "Internal server error"
          : error.message,
      };

      if (!config.hideNotification) {
        notifications.show(errorMessage);
      }
    },
  });
};

export const useFileUpload = () => {
  const token = localStorage.getItem("token");

  const storage = createWebStorageClient("/api", token);

  return useMutation({
    mutationFn: async (file) => {
      return storage.uploadFile(file);
    },
    onError: (error) => {
      notifications.show({
        title: "Error",
        color: "red",
        message: error.message,
      });
    },
  });
};
