import { Text } from "@mantine/core";
import { modals } from "@mantine/modals";

export default function approveRegistrationModal({
  registration,
  mutateAsync,
}) {
  modals.openConfirmModal({
    title: `Approve registration for ${registration?.companyName}?`,
    centered: true,
    children: (
      <Text size="sm">
        Approving the registration will generate and issue tickets for
        attendees, allowing them to access the event. This action is
        irreversible. Would you like to proceed?
      </Text>
    ),
    labels: {
      confirm: "Approve",
      cancel: "Cancel",
    },
    onConfirm: () => {
      try {
        mutateAsync({ id: registration.id, status: "approved" });
      } catch (error) {
        console.error("Failed to approve registration", error);
      } finally {
        modals.closeAll();
      }
    },
    confirmProps: {
      color: "teal",
    },
  });
}
