import React from "react";
import { countries } from "countries-list";
import { Group } from "@mantine/core";
import { Select } from "react-hook-form-mantine";
import Flags from "country-flag-icons/react/3x2";

/**
 * A country selection component that displays countries in a searchable dropdown.
 * Countries are grouped into "Ex-Yugoslavian Countries" and "Other Countries" categories.
 *
 * @component
 * @param {import("react-hook-form-mantine").SelectProps} props - The component props
 * @returns {JSX.Element} A Select component with grouped country options
 *
 * @example
 * <CountrySelect
 *   placeholder="Select a country"
 *   onChange={handleCountryChange}
 *   value={selectedCountry}
 *   name="country"
 *   control={control}
 * />
 */
function CountrySelect({ ...props }) {
  const yugoslavianCodes = ["RS", "BA", "HR", "MK", "ME", "SI"];

  const allCountries = Object.entries(countries).map(([code, country]) => ({
    value: code,
    label: country.name,
  }));

  const yugoslavianCountries = allCountries.filter((country) =>
    yugoslavianCodes.includes(country.value)
  );

  const otherCountries = allCountries
    .filter((country) => !yugoslavianCodes.includes(country.value))
    .sort((a, b) => a.label.localeCompare(b.label));

  const groupedData = [
    { group: "Ex-Yugoslavian Countries", items: yugoslavianCountries },
    { group: "Other Countries", items: otherCountries },
  ];

  return (
    <Select
      data={groupedData}
      searchable
      renderOption={renderOption}
      {...props}
    />
  );
}

function renderOption({ option }) {
  const FlagComponent = Flags[option.value.toUpperCase()];

  return (
    <Group flex="1" gap="xs">
      {FlagComponent && <FlagComponent height="12px" />}
      {option.label}
    </Group>
  );
}

export default CountrySelect;
