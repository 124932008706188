import { useApiQuery, useApiMutation } from "../services/react-query";

export const useGetAllRegistrations = (params) => {
  return useApiQuery({
    queryOptions: {
      queryKey: ["registrations", params],
      enabled: !!params.eventId,
    },
    fetchOptions: {
      url: "/registrations",
      method: "GET",
      params,
    },
  });
};

export const useGetRegistration = ({ registrationId }) => {
  return useApiQuery({
    queryOptions: { queryKey: ["registrations", registrationId] },
    fetchOptions: {
      url: `/registrations/${registrationId}`,
      method: "GET",
    },
  });
};

export const useCreateRegistration = () => {
  return useApiMutation({
    queryOptions: { queryKey: ["registrations"] },
    fetchOptions: {
      url: "/registrations",
      method: "POST",
    },
    hideNotification: true,
  });
};

export const useUpdateRegistration = () => {
  return useApiMutation({
    queryOptions: { queryKey: ["registrations"] },
    fetchOptions: {
      url: `/registrations`,
      method: "PATCH",
    },
    successMessage: {
      title: "Registration was updated successfully",
    },
    invalidateQueryKey: ["registrations"],
  });
};

export const useGetRegistrationsOverview = (params) => {
  return useApiQuery({
    queryOptions: {
      queryKey: ["registrations", "overview", params],
      enabled: !!params.eventId,
    },
    fetchOptions: {
      url: "/registrations/overview",
      method: "GET",
      params,
    },
  });
};

export const useGetRegistrationEvent = (params) => {
  return useApiQuery({
    queryOptions: {
      queryKey: ["registrations", "event", params],
      enabled: !!params.name,
    },
    fetchOptions: {
      url: "/registrations/event",
      method: "GET",
      params,
    },
  });
};
