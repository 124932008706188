import { Button } from "@mantine/core";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import posthog from "posthog-js";

import { AuthContext } from "../services/context";
import { queryClient } from "../services/react-query";

const LogOutButton = ({ ...props }) => {
  const navigate = useNavigate();

  const { user, setUser } = useContext(AuthContext);
  return (
    user && (
      <Button
        {...props}
        onClick={() => {
          localStorage.removeItem("token");
          setUser(null);
          queryClient.invalidateQueries("auth");

          // Capture logout event in PostHog
          posthog?.capture("logout");
          posthog?.reset();

          navigate("/login");
        }}
      >
        Sign out
      </Button>
    )
  );
};
export default LogOutButton;
