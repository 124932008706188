import { ActionIcon, Group, Select, ThemeIcon, Tooltip } from "@mantine/core";
import { IconChecks, IconEdit } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { DataTable } from "../DataTable";
import { useGetUsers } from "../../api/user";
import RoleBadges from "../RoleBadges";
import DeleteUserModal from "./DeleteUserModal";
import SendInviteMailModal from "./SendInviteMailModal";

function UsersTable() {
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    columnAccessor: "firstName",
    direction: "asc",
  });
  const [roleFilter, setRoleFilter] = useState(null);

  const { data, isLoading } = useGetUsers({
    page,
    limit: 100,
    sort,
    role: roleFilter,
  });

  const navigate = useNavigate();

  return (
    <DataTable
      records={data?.data}
      columns={[
        { accessor: "firstName", sortable: true },
        { accessor: "lastName", sortable: true },
        {
          accessor: "email",
          sortable: true,
          render: (row) => (
            <Group justify="space-between">
              {row.email}
              {row.isVerified ? (
                <Tooltip label="User registered">
                  <ThemeIcon color="green" variant="subtle" size="sm">
                    <IconChecks />
                  </ThemeIcon>
                </Tooltip>
              ) : (
                <SendInviteMailModal user={row} />
              )}
            </Group>
          ),
        },
        {
          accessor: "role",
          filter: (
            <Select
              label="Filter by role"
              placeholder="All roles"
              value={roleFilter}
              onChange={(value) => setRoleFilter(value)}
              data={[
                { value: "sysAdmin", label: "System Admins" },
                {
                  value: "wsAdmin",
                  label: "Workspace Admins",
                },
                { value: "member", label: "Members" },
              ]}
              clearable
            />
          ),
          render: (row) => {
            if (row?.config?.isTicketAdmin) {
              return (
                <>
                  <RoleBadges roleName="wsAdmin" />
                  <RoleBadges roleName="ticketAdmin" />
                </>
              );
            }

            return <RoleBadges roleName={row.role} />;
          },
        },
        {
          accessor: "actions",
          render: (row) => {
            return (
              <Group>
                <ActionIcon
                  color="blue"
                  variant="light"
                  title="Edit"
                  onClick={() => navigate(`/app/users/${row.id}`)}
                >
                  <IconEdit />
                </ActionIcon>
                <DeleteUserModal userId={row.id} />
              </Group>
            );
          },
        },
      ]}
      sortStatus={sort}
      onSortStatusChange={setSort}
      page={page}
      onPageChange={setPage}
      recordsPerPage={data?.pagination.perPage}
      totalRecords={data?.pagination.total}
      fetching={isLoading}
    />
  );
}

export default UsersTable;
