import {
  IconBrush,
  IconTags,
  IconCubeUnfolded,
  IconMessageChatbot,
  IconTimelineEvent,
  IconUsersGroup,
  IconCalendar,
  IconVersions,
  IconNotebook,
  IconMessage2,
  IconSpeakerphone,
  IconTicket,
  IconContract,
} from "@tabler/icons-react";

const links = [
  {
    path: "/app/workspaces",
    label: "Workspaces",
    icon: IconCubeUnfolded,
  },
  {
    path: "/app/events",
    label: "Events",
    icon: IconCalendar,
  },
  {
    path: "/app/users",
    label: "Users",
    icon: IconUsersGroup,
    roles: ["sysAdmin"],
  },
  {
    path: "/app/registrations",
    label: "Registrations",
    icon: IconContract,
  },
  {
    path: "/app/tickets",
    label: "Tickets",
    icon: IconTicket,
  },
  {
    path: "/app/events/customize",
    label: "Customization",
    icon: IconBrush,
  },
  {
    path: "/app/speakers",
    label: "Speakers",
    icon: IconSpeakerphone,
  },
  {
    path: "/app/sponsors",
    label: "Sponsors",
    icon: IconVersions,
  },
  {
    path: "/app/sessions",
    label: "Sessions",
    icon: IconTimelineEvent,
  },
  {
    path: "/app/tags",
    label: "Tags",
    icon: IconTags,
  },
  {
    path: "/app/polls",
    label: "Polls",
    icon: IconMessageChatbot,
  },
  {
    path: "/app/pages",
    label: "Pages",
    icon: IconNotebook,
  },
  {
    path: "/app/announcements",
    label: "Announcements",
    icon: IconMessage2,
  },
];

export const filteredLinks = (role) => {
  if (!role) {
    return null;
  }

  if (role === "ticketAdmin") {
    return links.filter((link) => {
      return link.label === "Tickets" || link.label === "Ticket History";
    });
  }

  return links.filter((link) => {
    if (link.roles) {
      return link.roles.includes(role);
    }
    return true;
  });
};

export default links;
