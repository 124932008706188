import { Card, Text, Group, Box } from "@mantine/core";
import { countries } from "countries-list";
import Flags from "country-flag-icons/react/3x2";

const AdditionalFieldDisplay = ({ fieldKey, value }) => {
  const renderCountry = () => {
    if (!value) {
      return <Text>Not provided</Text>;
    }
    const country = countries[value];
    const FlagComponent = Flags[value.toUpperCase()];
    return (
      <Group gap="xs" align="center">
        {FlagComponent && <FlagComponent height="12px" />}
        <Text>{country ? country.name : value}</Text>
      </Group>
    );
  };

  const renderArray = () => (
    <Box>
      {value.map((item, idx) => (
        <Text key={idx}>{item}</Text>
      ))}
    </Box>
  );

  const renderDefault = () => <Text>{value || "Not provided"}</Text>;

  const renderContent = () => {
    if (fieldKey === "Country") {
      return renderCountry();
    }
    if (Array.isArray(value)) {
      return renderArray();
    }
    return renderDefault();
  };

  return (
    <Card shadow="xs" p="sm" radius="md" withBorder>
      <Text size="sm" c="gray">
        {fieldKey}
      </Text>
      {renderContent()}
    </Card>
  );
};

export default AdditionalFieldDisplay;
