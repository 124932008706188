import { Avatar, Button, Paper, Skeleton, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useGetUser } from "../api/user";

/**
 * Renders a user information card with optional action button
 * @component
 * @param {Object} props - Component props
 * @param {string} props.userId - The ID of the user to display information for
 * @param {Object} [props.button] - Optional button configuration object
 * @param {Function} props.button.onClick - Click handler for the optional button
 * @param {boolean} props.button.disabled - Disabled state of the optional button
 * @param {string} props.button.label - Label text for the optional button
 * @param {import('@mantine/core').ButtonProps} props.button.props - Additional Mantine button props
 * @returns {JSX.Element} A card displaying user information with avatar, name, email, occupation and action buttons
 */
export function UserInfoAction({ userId, button }) {
  const navigate = useNavigate();

  const { data: user, isPending } = useGetUser(userId);

  return (
    <Paper
      radius="md"
      withBorder
      p="lg"
      bg="var(--mantine-color-body)"
      maw="250px"
    >
      {isPending ? (
        <Skeleton circle height={84} mx="auto" />
      ) : (
        <Avatar src={user?.picture} size="xl" radius={120} mx="auto">
          {user?.firstName[0]}
          {user?.lastName[0]}
        </Avatar>
      )}

      {isPending ? (
        <Skeleton height={28} mt="md" />
      ) : (
        <Text ta="center" fz="lg" fw={500} mt="md">
          {user?.firstName} {user?.lastName}
        </Text>
      )}

      {isPending ? (
        <Skeleton height={18} mt="sm" />
      ) : (
        <Text ta="center" c="dimmed" fz="sm">
          {user?.email} • {user?.occupation}
        </Text>
      )}

      <Button
        fullWidth
        color="blue"
        mt="lg"
        onClick={() => navigate(`/app/users/${user?.id}`)}
        size="xs"
      >
        View user profile
      </Button>

      {button && (
        <Button
          fullWidth
          color="blue"
          mt="lg"
          size="xs"
          onClick={button.onClick}
          disabled={button.disabled}
          {...button.props}
        >
          {button.label}
        </Button>
      )}
    </Paper>
  );
}
