import { useEffect, useState } from "react";
import {
  TextInput,
  Title,
  Group,
  Text,
  Switch,
  Button,
  Stack,
  Card,
  Box,
  Divider,
  Alert,
  ActionIcon,
  Tooltip,
  Transition,
  Badge,
  ThemeIcon,
} from "@mantine/core";
import {
  IconInfoCircle,
  IconPlus,
  IconColumns,
  IconDeviceFloppy,
  IconTrash,
} from "@tabler/icons-react";
import { closeModal, openConfirmModal } from "@mantine/modals";
import { useFieldArray, useForm, Controller } from "react-hook-form";

function CustomColumns({
  currentEventId,
  columns,
  defaultTicketColumns,
  customColumns,
  setCustomColumns,
  updateColumnsVisibility,
  makeCustomColumns,
  makeCustomColumnsSuccess,
}) {
  const [isCustomColumnsEnabled, setIsCustomColumnsEnabled] = useState(false);

  const {
    control,
    getValues,
    setValue,
    setError,
    formState: { isDirty, dirtyFields, errors },
    reset,
  } = useForm();

  useEffect(() => {
    setValue(
      "customColumns",
      customColumns.map((col) => ({ name: col.name, id: col.id }))
    );
  }, [customColumns, setValue]);

  const { fields, append, remove } = useFieldArray({
    name: "customColumns",
    control,
  });

  useEffect(() => {
    if (columns) {
      const customColumns = columns.filter(
        (column) => !defaultTicketColumns.includes(column.name)
      );
      setCustomColumns(customColumns);
      setIsCustomColumnsEnabled(
        customColumns.some((column) => column.isVisible)
      );
    }
  }, [
    columns,
    setCustomColumns,
    defaultTicketColumns,
    setIsCustomColumnsEnabled,
  ]);

  const validateColumnNames = (index, name) => {
    const existingNames = customColumns.map((col) => col.name);
    setError(`customColumns.${index}.name`, { type: "manual", message: "" });

    if (!name || name.trim() === "") {
      setError(`customColumns.${index}.name`, {
        type: "manual",
        message: "Column name cannot be empty.",
      });
    } else if (existingNames.includes(name)) {
      setError(`customColumns.${index}.name`, {
        type: "manual",
        message: "Column name must be unique.",
      });
    }
  };

  const handleUpdateCustomColumns = ({ data }) => {
    if (data.some((col) => !col.id && col.name.trim() === "")) {
      validateColumnNames(
        data.findIndex((col) => !col.id),
        data.find((col) => !col.id).name
      );
    }

    const hasErrors = Object.values(errors.customColumns || {}).some(
      (col) => col.name?.message
    );

    if (hasErrors) return;
    makeCustomColumns({ columns: data });

    reset({
      customColumns: data.map((col) => ({ name: col.name })),
    });
  };

  return (
    <Card
      shadow="sm"
      p="lg"
      radius="md"
      withBorder
      maw={{ sm: "100%", lg: "50%" }}
    >
      <Card.Section withBorder inheritPadding py="xs" bg="blue.0">
        <Group justify="space-between">
          <Group>
            <ThemeIcon size="lg" variant="light" color="blue">
              <IconColumns size={20} />
            </ThemeIcon>
            <Title order={4}>Custom Columns</Title>
            {isCustomColumnsEnabled && (
              <Badge color="blue" variant="light">
                Enabled
              </Badge>
            )}
          </Group>

          <Tooltip
            label={
              isCustomColumnsEnabled
                ? "Disable custom columns"
                : "Enable custom columns"
            }
          >
            <Switch
              size="md"
              color="blue"
              checked={isCustomColumnsEnabled}
              onChange={() => {
                openConfirmModal({
                  title: `${isCustomColumnsEnabled ? "Disable" : "Enable"} Custom Columns`,
                  children: (
                    <Text>
                      Are you sure you want to{" "}
                      {isCustomColumnsEnabled ? "disable" : "enable"} custom
                      columns?{" "}
                      {isCustomColumnsEnabled &&
                        "This will hide all custom columns from the table."}
                    </Text>
                  ),
                  labels: { confirm: "Yes, proceed", cancel: "Cancel" },
                  centered: true,
                  confirmProps: {
                    color: isCustomColumnsEnabled ? "red" : "blue",
                  },
                  onCancel: () => closeModal(),
                  onConfirm: async () => {
                    updateColumnsVisibility({
                      eventId: currentEventId,
                      columns: customColumns.map((column) => ({
                        id: column.id,
                        name: column.name,
                        isVisible: !isCustomColumnsEnabled,
                      })),
                    });

                    setIsCustomColumnsEnabled((prev) => !prev);
                    closeModal();
                  },
                });
              }}
            />
          </Tooltip>
        </Group>
      </Card.Section>

      <Alert
        icon={<IconInfoCircle size={16} />}
        title="How custom columns work"
        color="blue"
        variant="light"
        mt="md"
      >
        <Text size="sm" mb={5}>
          Custom columns allow you to add additional data fields to your tickets
          table.
          <br />
          They are visible by default but can be toggled in the column
          visibility section.
          <br />
          If all custom columns are hidden, the feature will be automatically
          disabled.
          <br />
          You can set column values when creating or editing tickets.
        </Text>
      </Alert>

      <Transition
        mounted={isCustomColumnsEnabled}
        transition="scale-y"
        duration={300}
      >
        {(styles) => (
          <Box style={styles} mt="xl">
            <Divider
              label="Manage Custom Columns"
              labelPosition="center"
              mb="md"
            />

            <Stack spacing="md">
              {fields.map((field, index) => (
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <Card
                      key={field.id}
                      withBorder
                      shadow="xs"
                      p="sm"
                      radius="md"
                    >
                      <Group position="apart" wrap="nowrap">
                        <TextInput
                          label="Column Name"
                          placeholder="Enter column name"
                          {...field}
                          flex={1}
                          onChange={(e) => {
                            field.onChange(e);
                            validateColumnNames(index, e.target.value);
                          }}
                          error={error?.message}
                        />

                        <Tooltip label="Remove column" position="right">
                          <ActionIcon
                            color="red"
                            variant="light"
                            size="lg"
                            mt={24}
                            onClick={() => remove(index)}
                          >
                            <IconTrash size={20} />
                          </ActionIcon>
                        </Tooltip>
                      </Group>
                    </Card>
                  )}
                  name={`customColumns.${index}.name`}
                  control={control}
                />
              ))}

              <Button
                leftSection={<IconPlus size={16} />}
                variant="outline"
                color="blue"
                onClick={() => append({ name: "" })}
                fullWidth
              >
                Add New Column
              </Button>
            </Stack>

            <Group position="right" mt="xl">
              <Button
                leftSection={<IconDeviceFloppy size={16} />}
                onClick={() =>
                  handleUpdateCustomColumns({
                    data: getValues("customColumns"),
                  })
                }
                disabled={!isDirty || !dirtyFields.customColumns}
                color="blue"
              >
                Save Changes
              </Button>
            </Group>
          </Box>
        )}
      </Transition>
    </Card>
  );
}

export default CustomColumns;
