import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ajvResolver } from "@hookform/resolvers/ajv";
import {
  Button,
  Container,
  Divider,
  Image,
  Title,
  Text,
  Center,
} from "@mantine/core";
import { useForm } from "react-hook-form";
import { PasswordInput, TextInput } from "react-hook-form-mantine";
import { GoogleLogin } from "@react-oauth/google";
import { useOs } from "@mantine/hooks";
import posthog from "posthog-js";

import { useLogin } from "../../api/auth";
import Logo from "../../assets/logo-02.svg";
import GoogleSignIn from "./GoogleSignIn";
import AppleSignInButton from "./AppleSignIn";

function Login() {
  const navigate = useNavigate();
  const os = useOs();

  const {
    mutate,
    data,
    isSuccess,
    isPending,
    isError,
    error: mutateError,
  } = useLogin();

  useEffect(() => {
    if (isError) {
      console.log("Login error", mutateError);
    }
    if (isSuccess && data) {
      localStorage.setItem("token", data.token);

      // Identify user in PostHog nad capture login event
      posthog?.identify(data.user.email, {
        user_id: data.user.id,
      });
      posthog?.capture("login");

      navigate("/callback");
    }
  }, [isError, isSuccess, data, navigate, mutateError]);

  const [error, setError] = useState(null);
  const [googleResponse, setGoogleResponse] = useState(null);

  const handleGoogleLogin = async (response) => {
    setGoogleResponse(response);
  };

  const handleError = (error) => {
    setError(error ? error : "An error occurred. Try again.");
    console.log(error);
  };

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: ajvResolver({
      type: "object",
      properties: {
        email: { type: "string", minLength: 1 },
        password: { type: "string", minLength: 1 },
      },
      required: ["email", "password"],
    }),
  });

  const onSubmit = (data) => {
    mutate(data);
    reset();
  };

  return (
    <Container size={420}>
      <Image src={Logo} alt="congrs logo" />
      <Title>Welcome to Congrs</Title>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <TextInput
          label="Email"
          placeholder="Enter your email"
          type="text"
          name="email"
          control={control}
          withAsterisk
          mt="md"
          disabled={isPending || isSuccess}
        />
        <PasswordInput
          label="Password"
          placeholder="Enter your password"
          name="password"
          control={control}
          withAsterisk
          mt="md"
          disabled={isPending || isSuccess}
        />
        <Button
          type="submit"
          variant="gradient"
          fullWidth
          size="md"
          my={10}
          disabled={isPending || isSuccess}
          loading={isPending}
        >
          Login
        </Button>
      </form>
      <Divider label="or" labelPosition="center" my={10} />

      <Center>
        <GoogleLogin
          onSuccess={handleGoogleLogin}
          onError={handleError}
          width={300}
          shape="pill"
        />
      </Center>
      {googleResponse && <GoogleSignIn data={googleResponse} />}

      {(os === "macos" || os === "ios") && (
        <Center>
          <AppleSignInButton style={{ width: 300 }} />
        </Center>
      )}

      {error && (
        <Text align="center" c="red">
          {error}
        </Text>
      )}
    </Container>
  );
}

export default Login;
