import React, { useContext } from "react";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { useGetRegistrationsOverview } from "../../api/registrations";
import { IconContract, IconInfoCircle, IconTicket } from "@tabler/icons-react";
import {
  Group,
  Card,
  Text,
  Skeleton,
  SimpleGrid,
  ThemeIcon,
  Flex,
  Badge,
  Tooltip,
  ActionIcon,
} from "@mantine/core";

function RegistrationSummary() {
  const { user } = useContext(AuthContext);
  const { eventId } = getCurrentWorkspace(user);
  const { data, isPending } = useGetRegistrationsOverview({ eventId });

  // Hardocding for ACI FMA
  const preferredOrder = [
    "Institution Name",
    "Attend Networking Event",
    "Attend Lunch",
    "Attend Gala Dinner",
  ];

  const additionalInfoDetails = data?.fieldsSummary
    ? Object.entries(data?.fieldsSummary)
        .sort((a, b) => {
          const indexA = preferredOrder.indexOf(a[0]);
          const indexB = preferredOrder.indexOf(b[0]);
          if (indexA === -1 && indexB === -1) {
            // Neither key in preferred order, sort alphabetically
            return a[0].localeCompare(b[0]);
          }
          if (indexA === -1) return 1;
          if (indexB === -1) return -1;
          return indexA - indexB;
        })
        .map(([key, value]) => ({
          label: key,
          value: value.true || 0,
        }))
    : [];

  if (isPending) {
    return (
      <Flex py="md">
        <SimpleGrid cols={2} spacing="lg">
          {[0, 2].map((index) => (
            <Card key={index} shadow="sm" p="lg" radius="md" withBorder>
              <Group position="apart" mb="md">
                <Skeleton height={36} width={36} radius="md" />
                <Skeleton height={24} width={120} />
              </Group>

              <Skeleton height={16} width={60} mb="xs" />
              <Skeleton height={28} width={80} mb="md" />

              <SimpleGrid cols={2} spacing="xs">
                {[0, 2].map((idx) => (
                  <div key={idx}>
                    <Skeleton height={16} width={80} mb="xs" />
                    <Group spacing="xs" align="center">
                      <Skeleton height={16} width={16} radius="xl" />
                      <Skeleton height={20} width={40} />
                    </Group>
                  </div>
                ))}
              </SimpleGrid>
            </Card>
          ))}
        </SimpleGrid>
      </Flex>
    );
  }

  // Use actual API data instead of example data
  const {
    total = 0,
    approved = 0,
    pending = 0,
    totalTickets = 0,
    approvedTickets = 0,
  } = data || {};

  const statCards = [
    {
      title: "Registrations",
      icon: <IconContract size={24} />,
      color: "blue",
      details: [
        { label: "Total", value: total },
        { label: "Approved", value: approved, color: "green" },
        { label: "Pending", value: pending, color: "yellow" },
      ],
      tooltip: {
        text: "This overview doesn't include rejected registrations",
        icon: <IconInfoCircle size={18} />,
      },
    },
    {
      title: "Tickets",
      icon: <IconTicket size={24} />,
      color: "violet",
      details: [
        { label: "Total", value: totalTickets },
        { label: "Issued", value: approvedTickets, color: "teal" },
        {
          label: "Waiting for approval",
          value: totalTickets - approvedTickets,
          color: "gray",
        },
      ],
      tooltip: {
        text: "This overview doesn't include rejected registrations",
        icon: <IconInfoCircle size={18} />,
      },
    },
    {
      title: "Additional information summary",
      icon: <IconInfoCircle size={24} />,
      color: "orange",
      details: additionalInfoDetails,
    },
  ];

  return (
    <Flex py="md">
      <SimpleGrid cols={{ lg: 3, md: 2, sm: 1 }} spacing="lg">
        {statCards.map((stat, index) => (
          <Card key={index} shadow="sm" p="lg" radius="md" withBorder>
            <Group position="apart" mb="md" wrap="nowrap">
              <ThemeIcon
                size="xl"
                radius="md"
                color={stat.color}
                variant="light"
              >
                {stat.icon}
              </ThemeIcon>
              <Group align="center" wrap="nowrap" gap="xs">
                <Text weight={500} size="lg" lineClamp={1}>
                  {stat.title}
                </Text>
                {stat.tooltip && (
                  <Tooltip
                    label={stat.tooltip.text}
                    position="top"
                    withArrow
                    transition="fade"
                    placement="center"
                  >
                    <ActionIcon
                      size="md"
                      variant="transparent"
                      color="gray"
                      radius="xl"
                    >
                      {stat.tooltip.icon}
                    </ActionIcon>
                  </Tooltip>
                )}
              </Group>
            </Group>

            <SimpleGrid cols={{ sm: 1, md: stat.details.length }} spacing="xs">
              {!stat.details.length && (
                <Text size="sm" c="dimmed" align="center">
                  No data available
                </Text>
              )}
              {stat.details.map((detail, idx) => (
                <Flex
                  align="center"
                  justify="space-between"
                  key={idx}
                  direction="column"
                  gap="sm"
                  wrap="nowrap"
                >
                  <Text
                    size="sm"
                    c="dimmed"
                    lineClamp={1}
                    style={{ maxWidth: "100%", textAlign: "center" }}
                    title={detail.label}
                  >
                    {detail.label}
                  </Text>

                  <Badge
                    size="xl"
                    color={detail.color}
                    radius="xl"
                    variant="light"
                  >
                    {detail.value}
                  </Badge>
                </Flex>
              ))}
            </SimpleGrid>
          </Card>
        ))}
      </SimpleGrid>
    </Flex>
  );
}

export default RegistrationSummary;
