import {
  Paper,
  Title,
  Text,
  Loader,
  Flex,
  Avatar,
  Button,
  Divider,
  Group,
  Tooltip,
  ActionIcon,
} from "@mantine/core";
import { IconPlus, IconQuestionMark, IconX } from "@tabler/icons-react";
import { useGetUserRecommendations } from "../../api/speakers";
import { UserInfoAction } from "../UserInfoAction";
import { SearchTicketHolders } from "../SearchTicketHolders";

function ConnectToUser({ speakerId, onUpdate, userId }) {
  const { data: users, isPending: usersLoading } =
    useGetUserRecommendations(speakerId);

  const handleUpdate = (userId) => {
    onUpdate(userId);
  };

  return (
    <Flex direction="column" flex={1} mt="sm">
      <Group align="center" gap="xs">
        <Title order={2}>Connected user</Title>
        <Tooltip
          label="Connect a user to this speaker. When you connect a user, the speaker will be clickable and will redirect to the user profile."
          multiline
        >
          <ActionIcon color="gray" size="sm" radius="xl">
            <IconQuestionMark />
          </ActionIcon>
        </Tooltip>
      </Group>
      {userId ? (
        <UserInfoAction
          userId={userId}
          button={{
            label: "Disconnect",
            onClick: () => handleUpdate(null),
            props: {
              color: "red",
              leftSection: <IconX />,
              mt: "sm",
              variant: "subtle",
            },
          }}
        />
      ) : (
        <Paper
          withBorder
          shadow="sm"
          p="md"
          mt="xs"
          style={{ overflow: "hidden" }}
        >
          <SearchTicketHolders onValueChange={handleUpdate} />
          <Divider label="or select from recommendations" my="sm" />
          {usersLoading && <Loader />}
          <Flex direction="column" gap="md">
            {users?.map((user) => (
              <SmallUserCard
                key={user.id}
                user={user}
                onConnect={() => handleUpdate(user.userId)}
              />
            ))}
          </Flex>
        </Paper>
      )}
    </Flex>
  );
}

function SmallUserCard({ user, onConnect }) {
  return (
    <Flex
      direction="row"
      align="center"
      justify="space-between"
      gap="sm"
      style={{ minWidth: "300px" }}
    >
      <Flex direction="row" align="center" gap="sm">
        <Avatar src={user.picture} size="md" radius="xl">
          {user.firstName[0]}
          {user.lastName[0]}
        </Avatar>
        <Flex direction="column">
          <Text size="sm">
            {user.firstName} {user.lastName}
          </Text>
          <Text size="xs" c="dimmed">
            {user.email} • {user.occupation}
          </Text>
        </Flex>
      </Flex>
      <Button
        size="xs"
        variant="subtle"
        color="blue"
        leftSection={<IconPlus />}
        onClick={() => onConnect(user.userId)}
      >
        Connect
      </Button>
    </Flex>
  );
}

export default ConnectToUser;
