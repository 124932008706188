import React from "react";
import { AppShell, Burger, Divider, ScrollArea } from "@mantine/core";

import Logo from "../Logo";
import { UserButton } from "../UserButton";
import classes from "./Navbar.module.css";
import NavbarLink from "./NavbarLink";
import { filteredLinks } from "./links";

function Navbar({ opened, toggle, user, isAuthenticated }) {
  // Check user role. If user role is wsAdmin, check user.config.isTicketAdmin
  // If user.config.isTicketAdmin is true, set role to ticketAdmin
  const role =
    user?.role === "wsAdmin" && user?.config?.isTicketAdmin
      ? "ticketAdmin"
      : user?.role;

  const links = filteredLinks(role);
  return (
    <AppShell.Navbar hidden={!opened} className={classes.navbar}>
      <div className={classes.header}>
        <Burger
          opened={opened}
          onClick={toggle}
          hiddenFrom="sm"
          size="sm"
          style={{ marginLeft: 10, marginTop: 10 }}
        />
        <Logo />
      </div>
      <ScrollArea className={classes.navbarMain}>
        {links.map((item, index) => {
          return (
            <React.Fragment key={item.label + index}>
              <NavbarLink
                item={item}
                key={item.label}
                onClick={toggle}
                disabled={item.disabled}
              />
              {index === 2 && <Divider key={`divider-${index}`} />}
            </React.Fragment>
          );
        })}
      </ScrollArea>

      <div className={classes.footer}>
        {!isAuthenticated && <p>Authentication failed</p>}
        <UserButton user={user} />
      </div>
    </AppShell.Navbar>
  );
}

export default Navbar;
