import { IconPlus } from "@tabler/icons-react";
import UsersTable from "../components/Users/UsersTable";
import { useSetActionButtons } from "../services/ActionButtonsContext";
import { useMemo } from "react";

function Users() {
  const actions = useMemo(
    () => [
      {
        label: "Create",
        icon: <IconPlus />,
        navigate: "./users/create",
      },
    ],
    []
  );

  useSetActionButtons(actions);
  return <UsersTable />;
}

export default Users;
