import React, { useContext } from "react";
import { AuthContext } from "../services/context";
import { getCurrentWorkspace } from "../services/userConfig";
import { useGetTicketHistory } from "../api/tickets";
import { Text, Group, Flex, Avatar } from "@mantine/core";

import { DataTable } from "../components/DataTable";
import RoleBadges from "../components/RoleBadges";
import TicketHistoryDashboard from "../components/TicketHistory/TicketHistoryDashboard";
import {
  displayChange,
  displayChangeStatusText,
  getTextStatus,
} from "../components/TicketHistory/utils";

function TicketHistory() {
  const { user } = useContext(AuthContext);
  const { eventId } = getCurrentWorkspace(user);

  const { data, isPending } = useGetTicketHistory({ eventId });

  if (isPending) {
    return <div>Loading...</div>;
  }
  return (
    <Flex direction="column" pt="sm">
      <TicketHistoryDashboard eventId={eventId} />

      <DataTable
        columns={[
          {
            accessor: "ticket",
            title: "Ticket holders",
            render: ({ firstName, lastName, occupation, picture }) => (
              <Flex direction="row" gap={10}>
                <Avatar
                  src={picture}
                  size="md"
                  radius="sm"
                  alt="avatar"
                  color="initials"
                  name={`${firstName} ${lastName}`}
                />
                <Flex direction="column">
                  <Text size="sm">
                    {firstName} {lastName}
                  </Text>
                  <Text size="sm" c="dimmed">
                    {occupation}
                  </Text>
                </Flex>
              </Flex>
            ),
          },
          {
            accessor: "change",
            title: "Changes",
            render: (row) =>
              row?.change?.map((change, idx) => (
                <Text key={idx} size="sm">
                  {displayChange(change)}
                </Text>
              )),
          },
          {
            accessor: "changedBy",
            title: "Changed by",
            render: (row) => (
              <Group>
                <Text size="sm">
                  {row?.changedBy?.firstName} {row?.changedBy?.lastName}
                </Text>
                <RoleBadges roleName={row?.changedBy?.role} />
              </Group>
            ),
          },
          { accessor: "updatedAt", title: "Updated" },
          {
            accessor: "status",
            title: "Status",
            render: (row) => {
              const statusText = displayChangeStatusText(
                row.change,
                row.changeStatus
              );
              const statusColor = getTextStatus(statusText);

              return (
                <Text c={statusColor} size="sm">
                  {statusText}
                </Text>
              );
            },
          },
        ]}
        records={data}
        fetching={isPending}
      />
    </Flex>
  );
}

export default TicketHistory;
