import React from "react";
import { Tabs } from "@mantine/core";
import { useSearchParams } from "react-router-dom";
import AnnouncementsComponent from "../components/Announcements/Announcements";
import SpecialInvitation from "./SpecialInvitation";
import { IconNotification, IconStar } from "@tabler/icons-react";

function Announcements() {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = (tab) => {
    setSearchParams({ tab });
  };

  const tabValue = searchParams.get("tab") || "announcements";

  return (
    <Tabs
      keepMounted={false}
      variant="outline"
      value={tabValue}
      onChange={(value) => handleTabChange(value)}
    >
      <Tabs.List>
        <Tabs.Tab
          value="announcements"
          leftSection={<IconNotification size={12} />}
        >
          Announcements
        </Tabs.Tab>
        <Tabs.Tab
          value="special-invitation"
          leftSection={<IconStar size={12} />}
        >
          Special Invitation
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="announcements">
        <AnnouncementsComponent />
      </Tabs.Panel>
      <Tabs.Panel value="special-invitation">
        <SpecialInvitation />
      </Tabs.Panel>
    </Tabs>
  );
}

export default Announcements;
