import React from "react";
import {
  Card,
  Text,
  Group,
  Stack,
  ActionIcon,
  Divider,
  Badge,
  Tooltip,
} from "@mantine/core";
import { IconTicket, IconCheck, IconX } from "@tabler/icons-react";
import { Link } from "react-router-dom";

function TicketCard({ ticket }) {
  // Function to render field values based on type
  const renderFieldValue = (value) => {
    if (typeof value === "boolean") {
      return value ? (
        <Badge
          color="green"
          variant="light"
          leftSection={<IconCheck size={12} />}
        >
          Yes
        </Badge>
      ) : (
        <Badge color="red" variant="light" leftSection={<IconX size={12} />}>
          No
        </Badge>
      );
    } else if (Array.isArray(value)) {
      return (
        <Text size="sm" lineClamp={1} title={value.join(", ")}>
          {value.join(", ")}
        </Text>
      );
    } else {
      return (
        <Text size="sm" lineClamp={1} title={value || "Not provided"}>
          {value || "Not provided"}
        </Text>
      );
    }
  };

  const hasAdditionalFields =
    ticket?.additionalFields && Object.keys(ticket.additionalFields).length > 0;

  // Custom sort for additional fields for ACI FMA
  const sortAdditionalFields = (fields) => {
    const order = [
      "Institution Name",
      "Attend Networking Event",
      "Attend Lunch",
      "Attend Gala Dinner",
    ];
    return fields.sort((a, b) => {
      const indexA = order.indexOf(a[0]);
      const indexB = order.indexOf(b[0]);
      if (indexA === -1 && indexB === -1) {
        return a[0].localeCompare(b[0]);
      }
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    });
  };

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Card.Section withBorder inheritPadding py="xs">
        <Group position="apart">
          <Text fw={700} size="lg">
            {ticket?.firstName || ""} {ticket?.lastName || ""}
          </Text>
          {ticket?.ticketId && (
            <ActionIcon
              variant="light"
              color="teal"
              component={Link}
              title="View ticket details"
              to={`/app/tickets/${ticket.ticketId}`}
            >
              <IconTicket size="1.125rem" />
            </ActionIcon>
          )}
        </Group>
      </Card.Section>

      <Stack mt="md" spacing="xs">
        <Group position="apart" wrap="nowrap" spacing="xl">
          <Text fw={500}>Email:</Text>
          <Text lineClamp={1} title={ticket?.email || "N/A"}>
            {ticket?.email || "N/A"}
          </Text>
        </Group>

        {hasAdditionalFields && (
          <>
            <Divider
              my="xs"
              label="Additional Information"
              labelPosition="center"
            />

            <Stack>
              {sortAdditionalFields(
                Object.entries(ticket.additionalFields)
              ).map(([key, value]) => (
                <Group key={key} position="apart" spacing="xs" wrap="nowrap">
                  <Tooltip label={key} position="top-start" withinPortal>
                    <Text
                      size="xs"
                      c="dimmed"
                      lineClamp={1}
                      style={{ maxWidth: "60%" }}
                      fw={500}
                    >
                      {key}:
                    </Text>
                  </Tooltip>
                  <div style={{ flexGrow: 1, textAlign: "right" }}>
                    {renderFieldValue(value)}
                  </div>
                </Group>
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </Card>
  );
}

export default TicketCard;
