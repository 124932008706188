import { Flex } from "@mantine/core";
import RegistrationSummary from "../components/Registrations/Overview";
import RegistrationsTable from "../components/Registrations/Table";
import { useContext } from "react";
import { AuthContext } from "../services/context";
import { getCurrentWorkspace } from "../services/userConfig";
import FeatureNotAvailable from "../components/FeatureNotAvailable";

function Registrations() {
  const { user } = useContext(AuthContext);

  const currentEventId = getCurrentWorkspace(user).eventId;

  const currentEvent = user.events.find((event) => event.id === currentEventId);

  if (!currentEvent) {
    return null;
  }

  if (currentEvent.name !== "ACI Serbia 50th Anniversary") {
    return <FeatureNotAvailable />;
  }

  return (
    <Flex direction="column" grow={1} style={{ overflowY: "auto" }}>
      <RegistrationSummary />
      <RegistrationsTable />
    </Flex>
  );
}

export default Registrations;
