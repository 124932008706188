import { useState } from "react";
import {
  Text,
  Group,
  Button,
  Container,
  Title,
  List,
  Card,
  ThemeIcon,
  Box,
  Alert,
  Badge,
  Stack,
  Progress,
  Collapse,
} from "@mantine/core";
import {
  IconFile,
  IconFileImport,
  IconCheck,
  IconAlertCircle,
  IconX,
  IconAlertTriangle,
  IconChevronDown,
  IconChevronRight,
} from "@tabler/icons-react";
import { useContext } from "react";

import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { useImportTickets } from "../../api/tickets";
import { toBase64 } from "../../services/helper";
import CSVDropzoneButton from "../CSVDropzone";

function ImportExportTickets() {
  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);
  const currentEventId = currentWorkspace.eventId;
  const [showErrors, setShowErrors] = useState(false);

  const [file, setFile] = useState(null);
  const {
    mutate: importMutate,
    isPending: isImporting,
    data: importData,
    isSuccess: importSuccess,
    reset: resetImport,
  } = useImportTickets();

  const importStats = {
    total: importData?.total || 0,
    successful: importData?.successfullyInserted || 0,
    errors: {
      count: importData
        ? (importData.errors?.insertErrors?.length || 0) +
          (importData.errors?.validationErrors?.length || 0)
        : 0,
      insertErrors: importData?.errors?.insertErrors || [],
      validationErrors: importData?.errors?.validationErrors || [],
    },
  };

  const handleImport = async () => {
    const base64file = await toBase64(file);
    const data = {
      file: {
        name: file.name,
        type: file.type,
        data: base64file,
      },
      eventId: currentEventId,
    };
    importMutate(data);
  };

  // Function to render error details
  const ErrorDetails = () => {
    if (importStats.errors.count === 0) return null;

    return (
      <Card withBorder shadow="sm" radius="md" mt="md" bg="red.0">
        <Card.Section withBorder inheritPadding py="xs" bg="red.1">
          <Group position="apart">
            <Group>
              <ThemeIcon color="red" variant="light" size="md">
                <IconAlertTriangle size={16} />
              </ThemeIcon>
              <Text fw={500}>Error Details</Text>
            </Group>
            <Button
              variant="subtle"
              color="red"
              rightSection={
                showErrors ? (
                  <IconChevronDown size={16} />
                ) : (
                  <IconChevronRight size={16} />
                )
              }
              onClick={() => setShowErrors(!showErrors)}
            >
              {showErrors ? "Hide Details" : "Show Details"}
            </Button>
          </Group>
        </Card.Section>

        <Collapse in={showErrors}>
          <Box pt="xs">
            {importStats.errors.validationErrors.length > 0 && (
              <>
                <Title order={6} mb="xs" mt="md">
                  Validation Errors
                </Title>
                {importStats.errors.validationErrors.map((error, index) => (
                  <Text key={`validation-${index}`} size="sm" color="red">
                    {JSON.stringify(error)}
                  </Text>
                ))}
              </>
            )}

            {importStats.errors.insertErrors.length > 0 && (
              <>
                <Title order={6} mb="xs" mt="md">
                  Insert Errors
                </Title>
                {importStats.errors.insertErrors.map((error, index) => (
                  <Text key={`insert-${index}`} size="sm" color="red">
                    {JSON.stringify(error)}
                  </Text>
                ))}
              </>
            )}
          </Box>
        </Collapse>
      </Card>
    );
  };

  return (
    <Container size="lg" mt="md">
      <Card shadow="sm" p="lg" radius="md" withBorder>
        <Card.Section withBorder inheritPadding py="xs" bg="blue.0">
          <Group>
            <ThemeIcon size="lg" variant="light" color="blue">
              <IconFileImport size={20} />
            </ThemeIcon>
            <Title order={4}>Import Tickets</Title>
          </Group>
        </Card.Section>

        <Alert
          icon={<IconAlertCircle size={16} />}
          title="File Requirements"
          color="blue"
          variant="light"
          mt="md"
        >
          <List size="sm">
            <List.Item>File must be in .csv or .xlsx format</List.Item>
            <List.Item>File size must be less than 10MB</List.Item>
            <List.Item>
              Required columns: first name, last name, email
            </List.Item>
            <List.Item>
              Optional columns: category [attendee/speaker/sponsor/organizer]
            </List.Item>
          </List>
        </Alert>

        <Box
          my="md"
          p="md"
          sx={{
            border: "1px dashed var(--mantine-color-gray-4)",
            borderRadius: "8px",
            backgroundColor: "var(--mantine-color-gray-0)",
          }}
        >
          {!file ? (
            <CSVDropzoneButton onDrop={setFile} />
          ) : (
            <Stack spacing="xs" align="center">
              <Group>
                <ThemeIcon size="xl" variant="light" radius="xl" color="blue">
                  <IconFile size={24} />
                </ThemeIcon>
                <Stack spacing={0}>
                  <Text fw={500}>{file.name}</Text>
                  <Text size="xs" c="dimmed">
                    {(file.size / 1024 / 1024).toFixed(2)} MB
                  </Text>
                </Stack>
              </Group>

              <Group mt={8} grow w="100%">
                <Button
                  leftSection={<IconCheck size={16} />}
                  onClick={handleImport}
                  color="blue"
                  disabled={!file || importSuccess}
                  loading={isImporting}
                  fullWidth
                >
                  Import File
                </Button>
                <Button
                  leftSection={<IconX size={16} />}
                  onClick={() => {
                    setFile(null);
                    resetImport();
                  }}
                  variant="outline"
                  color="red"
                  disabled={isImporting}
                  fullWidth
                >
                  Clear File
                </Button>
              </Group>
            </Stack>
          )}
        </Box>

        {importData && importSuccess && file && (
          <>
            <Alert
              icon={
                importStats.errors.count ? (
                  <IconAlertCircle size={16} />
                ) : (
                  <IconCheck size={16} />
                )
              }
              title={
                importStats.errors.count
                  ? "Import Completed with Errors"
                  : "Import Successful"
              }
              color={importStats.errors.count ? "yellow" : "green"}
              variant="light"
              mt="md"
            >
              <Stack spacing="xs">
                <Text size="sm">
                  Successfully imported {importStats.successful} out of{" "}
                  {importStats.total} tickets.
                </Text>

                <Progress
                  value={(importStats.successful / importStats.total) * 100}
                  color={importStats.errors.count ? "yellow" : "green"}
                />

                {importStats.errors.count > 0 && (
                  <Group>
                    <Badge color="red">{importStats.errors.count} errors</Badge>
                    <Text size="xs" c="dimmed">
                      Click on "Show Details" below to see error information
                    </Text>
                  </Group>
                )}
              </Stack>
            </Alert>

            {importStats.errors.count > 0 && <ErrorDetails />}
          </>
        )}
      </Card>
    </Container>
  );
}

export default ImportExportTickets;
