import { Paper, Text, Title, Button, Container } from "@mantine/core";
import { useForm } from "react-hook-form";
import { Textarea, TextInput } from "react-hook-form-mantine";
import { useGetSpeakerById, usePatchSpeaker } from "../../api/speakers";
import { useParams } from "react-router-dom";
import { extractDirtyData } from "../../utils/dirtyData";
import ImageUpload from "../ImageUpload";
import ConnectToUser from "./ConnectToUser";

function EditSpeakerForm({ speaker }) {
  const { firstName, lastName, occupation, biography, id, picture, userId } =
    speaker;

  const defaultValues = {
    firstName,
    lastName,
    occupation,
    biography,
    picture: picture ?? "",
    userId,
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues,
  });

  const { mutate, isPending } = usePatchSpeaker(id);

  const onSubmit = (data) => {
    const dirtyData = extractDirtyData(data, dirtyFields);

    mutate(dirtyData, {
      onSuccess: () => {
        reset({ ...defaultValues, ...dirtyData });
      },
    });
  };

  const handleUserIdUpdate = (userId) => {
    mutate(
      { userId },
      { onSuccess: () => reset({ ...defaultValues, userId }) }
    );
  };

  return (
    <Container w={600}>
      <Title order={1}>Edit speaker</Title>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper withBorder shadow="sm" px="sm" py="md" style={{ marginTop: 20 }}>
          <ImageUpload
            control={control}
            name="picture"
            label="Profile picture"
            defaultImage={picture}
            resize
          />

          <TextInput
            label="First Name"
            placeholder="John"
            name="firstName"
            control={control}
            mt="sm"
            required
          />
          <TextInput
            label="Last Name"
            placeholder="Doe"
            name="lastName"
            control={control}
            required
            mt="sm"
          />
          <TextInput
            label="Occupation"
            placeholder="Software Engineer at RoundCube"
            name="occupation"
            control={control}
            mt="sm"
          />
          <Textarea
            label="Biography"
            placeholder="Tell us about the speaker"
            name="biography"
            control={control}
            rows={3}
            mt="sm"
          />

          <Button
            type="submit"
            color="blue"
            style={{ marginTop: 20 }}
            loading={isPending}
            disabled={!isDirty}
          >
            Edit speaker
          </Button>
        </Paper>
      </form>
      <ConnectToUser
        speakerId={id}
        onUpdate={handleUserIdUpdate}
        userId={userId}
      />
    </Container>
  );
}

const EditSpeaker = () => {
  const { speakerId } = useParams();
  const {
    data: speaker,
    isPending: speakerLoading,
    isSuccess,
  } = useGetSpeakerById(speakerId);

  if (speakerLoading) {
    return <Text>Loading...</Text>;
  }

  if (isSuccess) {
    return <EditSpeakerForm speaker={speaker} />;
  }
};

export default EditSpeaker;
