import { Text } from "@mantine/core";
import { modals } from "@mantine/modals";

export default function rejectRegistrationModal({ registration, mutateAsync }) {
  modals.openConfirmModal({
    title: `Reject registration for ${registration?.companyName}?`,
    centered: true,
    children: (
      <Text size="sm">
        Rejecting the registration will prevent attendees from accessing the
        event, and the status cannot be changed afterward. Would you like to
        proceed with the rejection?
      </Text>
    ),
    labels: {
      confirm: "Reject",
      cancel: "Cancel",
    },
    onConfirm: () => {
      try {
        mutateAsync({ id: registration.id, status: "rejected" });
      } catch (error) {
        console.error("Failed to reject registration", error);
      } finally {
        modals.closeAll();
      }
    },
    confirmProps: {
      color: "red",
    },
  });
}
