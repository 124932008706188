import { useContext, useMemo, useState } from "react";
import { AuthContext } from "../services/context";
import { useGetAllAnnouncements } from "../api/announcements";
import { getCurrentWorkspace } from "../services/userConfig";
import AnnouncementsTable from "../components/Announcements/AnnouncementsTable";
import { useSetActionButtons } from "../services/ActionButtonsContext";
import { IconPlus } from "@tabler/icons-react";
import { Box } from "@mantine/core";

function SpecialInvitation() {
  const actions = useMemo(
    () => [
      {
        label: "Create",
        icon: <IconPlus />,
        navigate: "./special-invitation/create",
      },
    ],
    []
  );
  useSetActionButtons(actions);

  const { user } = useContext(AuthContext);
  const { eventId } = getCurrentWorkspace(user);

  const [pagination, setPagination] = useState({ page: 1, limit: 10 });

  const { data, isSuccess } = useGetAllAnnouncements({
    type: "special",
    getAll: true,
    eventId,
    ...pagination,
  });

  return (
    <>
      {isSuccess && (
        <Box py="sm">
          <AnnouncementsTable
            data={data}
            pagination={{ data: pagination, setPagination }}
            hideTextField
          />
        </Box>
      )}
    </>
  );
}

export default SpecialInvitation;
