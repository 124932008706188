/**
 * Configuration management for the Congrs web application
 * Handles environment-specific settings and secrets
 */

// Define environment values
const ENV = {
  LOCAL: "local",
  DEV: "development",
  PROD: "production",
};

/**
 * Safely retrieves environment variables across Node and browser contexts
 * @param {string} key - Environment variable name
 * @param {any} defaultValue - Default value if not found
 * @returns {any} The environment variable value or default
 */
function getEnv(key, defaultValue = undefined) {
  // Node.js environment (build time)
  if (typeof process !== "undefined" && process.env && process.env[key]) {
    return process.env[key];
  }

  // Browser environment (runtime with Vite)
  if (
    typeof import.meta !== "undefined" &&
    import.meta.env &&
    import.meta.env[key]
  ) {
    return import.meta.env[key];
  }

  return defaultValue;
}

/**
 * Determine current environment
 */
const currentEnv = getEnv("VITE_CLOUD_ENV", getEnv("CLOUD_ENV", ENV.LOCAL));
const LOCAL_IP = getEnv("VITE_LOCAL_IP");

/**
 * Environment-specific URLs
 */
const environmentConfig = {
  [ENV.LOCAL]: {
    baseUrl: LOCAL_IP ? `http://${LOCAL_IP}:5173` : "http://localhost:5173",
    apiUrl: LOCAL_IP
      ? `http://${LOCAL_IP}:3000/api`
      : "http://localhost:3000/api",
  },
  [ENV.DEV]: {
    baseUrl: "https://app.dev.congrs.com",
    apiUrl: "http://app.dev.congrs.com/api",
  },
  [ENV.PROD]: {
    baseUrl: "https://app.congrs.com",
    apiUrl: "https://app.congrs.com/api",
  },
};

/**
 * Service configurations with environment-based secrets
 */
const serviceConfig = {
  posthog: {
    key: getEnv("VITE_POSTHOG_KEY", ""),
    host: getEnv("VITE_POSTHOG_HOST", "https://eu.posthog.com"),
  },

  OAuth: {
    webClientId: getEnv("VITE_GOOGLE_CLIENT_ID", ""),
  },

  sentry: {
    dsn: getEnv("VITE_SENTRY_DSN"),
  },
};

/**
 * Build and export the final configuration
 */
export const webConfig = {
  // Combine configs
  ...environmentConfig[currentEnv],
  ...serviceConfig,

  // Environment flags for conditional logic
  isLocal: currentEnv === ENV.LOCAL,
  isDevelopment: currentEnv === ENV.DEV,
  isProduction: currentEnv === ENV.PROD,
};
