import { createContext, useContext, useEffect, useState } from "react";

const ActionButtonsContext = createContext();

export function ActionButtonsProvider({ children }) {
  const [actionButtons, setActionButtons] = useState([]);

  return (
    <ActionButtonsContext.Provider value={{ actionButtons, setActionButtons }}>
      {children}
    </ActionButtonsContext.Provider>
  );
}

export function useActionButtons() {
  return useContext(ActionButtonsContext);
}

/**
 * A hook to set action buttons in a global context
 * @param {Array<{label: string, icon: JSX.Element, onClick: () => void, navigate: string}>} actions - Array of action button configurations
 * @returns {void}
 * @description Sets action buttons in the global context and cleans up on unmount
 * @example
 * const actions = [
 *   {
 *     label: "Add",
 *     icon: <PlusIcon />,
 *     onClick: () => console.log("clicked")
 *   }
 * ];
 * useSetActionButtons(actions);
 */
export function useSetActionButtons(rawActions) {
  const { setActionButtons } = useActionButtons();

  useEffect(() => {
    const wrappedActions = rawActions.map((action) => ({
      ...action,
      onClick: (...args) => {
        action?.onClick?.(...args);
      },
    }));

    setActionButtons(wrappedActions);

    return () => setActionButtons([]);
  }, [setActionButtons, rawActions]);
}
