import { Tabs } from "@mantine/core";
import TempTickets from "../components/Tickets/Tickets";
import TicketHistory from "./TicketHistory";
import TableSettings from "../components/Tickets/TableSettings/TableSettings";
import {
  IconFileExport,
  IconFileImport,
  IconHistory,
  IconSettings,
  IconTicket,
} from "@tabler/icons-react";
import { useSearchParams } from "react-router-dom";
import ImportExportTickets from "../components/Tickets/ImportTickets";
import ExportTickets from "../components/Tickets/ExportTickets";

function Tickets() {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = (tab) => {
    setSearchParams({ tab });
  };

  const tabValue = searchParams.get("tab") || "tickets";

  return (
    <Tabs
      keepMounted={false}
      variant="outline"
      value={tabValue}
      onChange={(value) => handleTabChange(value)}
    >
      <Tabs.List>
        <Tabs.Tab value="tickets" leftSection={<IconTicket size={12} />}>
          Tickets
        </Tabs.Tab>
        <Tabs.Tab value="history" leftSection={<IconHistory size={12} />}>
          History
        </Tabs.Tab>
        <Tabs.Tab value="import" leftSection={<IconFileImport size={12} />}>
          Import
        </Tabs.Tab>
        <Tabs.Tab value="export" leftSection={<IconFileExport size={12} />}>
          Export
        </Tabs.Tab>
        <Tabs.Tab value="settings" leftSection={<IconSettings size={12} />}>
          Settings
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="tickets">
        <TempTickets />
      </Tabs.Panel>
      <Tabs.Panel value="history">
        <TicketHistory />
      </Tabs.Panel>
      <Tabs.Panel value="import">
        <ImportExportTickets />
      </Tabs.Panel>
      <Tabs.Panel value="export">
        <ExportTickets />
      </Tabs.Panel>
      <Tabs.Panel value="settings">
        <TableSettings />
      </Tabs.Panel>
    </Tabs>
  );
}

export default Tickets;
