import { useState, useContext, useRef, useEffect, useMemo } from "react";
import TicketsTable from "./Table";
import { useGetAllTickets } from "../../api/tickets";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { useDebouncedValue } from "@mantine/hooks";
import { useSetActionButtons } from "../../services/ActionButtonsContext";
import { IconMail, IconPlus } from "@tabler/icons-react";
import { openSendMailModal } from "./SendEmail";

function Tickets() {
  const tableRef = useRef();
  const [selectedTickets, setSelectedTickets] = useState([]);

  const handleAddNewTicket = () => {
    tableRef.current?.addNewRow();
  };

  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);
  const currentEventId = currentWorkspace.eventId;

  const [pagination, setPagination] = useState({ page: 1, limit: 50 });
  const [sorting, setSorting] = useState({
    columnAccessor: "firstName",
    direction: "asc",
  });
  const [filter, setFilter] = useState(null);
  const [debouncedFilter] = useDebouncedValue(filter, 200);

  const { data, isPending } = useGetAllTickets({
    eventId: currentEventId,
    ...pagination,
    sort: sorting,
    filter: debouncedFilter,
  });

  const actions = useMemo(
    () => [
      {
        label: "Send Emails",
        onClick: () => {
          openSendMailModal({
            ticketIds: selectedTickets,
            eventId: currentEventId,
            totalTickets: data?.pagination?.total,
          });
        },
        icon: <IconMail />,
        disabled: !selectedTickets.length,
      },
      {
        label: "Create",
        onClick: handleAddNewTicket,
        icon: <IconPlus />,
      },
    ],
    [selectedTickets, currentEventId, data?.pagination?.total]
  );

  useSetActionButtons(actions);

  // When changing an event, we need to reset the selected tickets
  // and pagination
  useEffect(() => {
    setSelectedTickets([]);
    setPagination({ page: 1, limit: 50 });
    setFilter(null);
    setSorting({ columnAccessor: "firstName", direction: "asc" });
  }, [currentEventId]);

  return (
    <TicketsTable
      ref={tableRef}
      data={data}
      pagination={{ data: pagination, setPagination }}
      sorting={{ data: sorting, setSorting }}
      isPending={isPending}
      actions={{
        openSendEmailModal: ({ ticketIds }) => {
          openSendMailModal({ ticketIds, eventId: currentEventId });
        },
      }}
      filter={{ data: filter, setFilter }}
      selectedIds={selectedTickets}
      onSelectedIdsChange={(selectedTickets) => {
        setSelectedTickets(selectedTickets);
      }}
    />
  );
}

export default Tickets;
