import { useDataTableColumns } from "mantine-datatable";
import {
  Button,
  Group,
  Card,
  Text,
  Alert,
  Title,
  Stack,
  Loader,
  Center,
  Badge,
  Divider,
  ThemeIcon,
  Box,
  Transition,
} from "@mantine/core";
import {
  IconInfoCircle,
  IconEye,
  IconRefresh,
  IconDeviceFloppy,
} from "@tabler/icons-react";
import { useState } from "react";
import { DataTable } from "../../DataTable";

function ColumnVisibility({
  currentEventId,
  visibleColumns,
  setVisibleColumns,
  updateColumnsVisibility,
  updateColumnsVisibilitySuccess,
  customColumns,
  allColumns,
}) {
  const { effectiveColumns, resetColumnsToggle, columnsToggle } =
    useDataTableColumns({
      key: "settings",
      columns: allColumns.map((column) => ({
        ...column,
        isVisible: visibleColumns.some(
          (visibleColumn) => visibleColumn.accessor === column.accessor
        ),
      })),
    });

  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  // Function to reset columns visibility to default
  const resetColumnsVisibility = () => {
    setVisibleColumns(
      allColumns.map((column) => ({ ...column, isVisible: true }))
    );
    resetColumnsToggle();

    // Save this change so that it persists
    updateColumnsVisibility({
      eventId: currentEventId,
      columns: allColumns.map((column) => ({
        name: column.accessor,
        isVisible: true,
      })),
    });

    setLoading(true);
    setSaved(false);

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  // Handle saving visibility settings
  const handleSaveVisibility = () => {
    updateColumnsVisibility({
      eventId: currentEventId,
      columns: columnsToggle.map((column) => ({
        name: column.accessor,
        isVisible: column.toggled,
      })),
    });

    setSaved(true);
    setTimeout(() => setSaved(false), 3000);
  };

  // Create example data for the table
  const exampleData = setExampleData(customColumns);

  // Count visible columns
  const visibleColumnsCount = columnsToggle.filter((col) => col.toggled).length;

  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Card.Section withBorder inheritPadding py="xs" bg="blue.0">
        <Group justify="space-between">
          <Group>
            <ThemeIcon size="lg" variant="light" color="blue">
              <IconEye size={20} />
            </ThemeIcon>
            <Title order={4}>Column Visibility</Title>
            <Badge color="blue" variant="light">
              {visibleColumnsCount} of {allColumns.length} columns visible
            </Badge>
          </Group>
        </Group>
      </Card.Section>

      <Alert
        icon={<IconInfoCircle size={16} />}
        title="Managing Columns"
        color="blue"
        variant="light"
        mt="md"
      >
        <Text size="sm" mb={5}>
          Configure which columns appear in your tickets table. <br />
          Remove columns by clicking the X button in the column header. <br />
          Required columns for ticket creation (First Name, Last Name, Email)
          cannot be hidden.
        </Text>
      </Alert>

      <Divider label="Preview Table" labelPosition="center" my="md" />

      <Box mt="md" mb="xl">
        {loading ? (
          <Center style={{ height: 200 }}>
            <Stack align="center" spacing="xs">
              <Loader size="md" color="blue" />
              <Text size="sm" color="dimmed">
                Resetting columns...
              </Text>
            </Stack>
          </Center>
        ) : (
          effectiveColumns &&
          effectiveColumns.length > 0 && (
            <DataTable
              records={exampleData}
              columns={effectiveColumns}
              storeColumnsKey={"settings"}
              page={1}
              onPageChange={() => {}}
              totalRecords={exampleData.length}
              recordsPerPage={5}
              style={{ width: "100%" }}
            />
          )
        )}
      </Box>

      <Divider my="md" />

      <Group position="apart" mt="md">
        <Button
          leftIcon={<IconRefresh size={16} />}
          variant="outline"
          color="blue"
          onClick={resetColumnsVisibility}
          disabled={visibleColumns.length === allColumns.length || loading}
        >
          Reset All Columns
        </Button>

        <Group>
          <Transition mounted={saved} transition="fade" duration={400}>
            {(styles) => (
              <Badge style={styles} color="green" variant="light">
                Settings saved!
              </Badge>
            )}
          </Transition>

          <Button
            leftIcon={<IconDeviceFloppy size={16} />}
            color="blue"
            onClick={handleSaveVisibility}
            disabled={
              columnsToggle.every((column) => column.toggled) || loading
            }
          >
            Save Column Settings
          </Button>
        </Group>
      </Group>
    </Card>
  );
}

// Function to set example data for the table
const setExampleData = (customColumns) => {
  const exampleData = [
    {
      id: 1,
      firstName: "John",
      lastName: "Doe",
      email: "john@mail.com",
      status: "new",
      category: "attendee",
      paid: true,
      amountPaid: 100,
      currency: "USD",
      isApproved: true,
    },
    {
      id: 2,
      firstName: "Mary",
      lastName: "Doe",
      email: "mary@mail.com",
      status: "new",
      category: "speaker",
      paid: false,
      amountPaid: 0,
      currency: "USD",
      isApproved: false,
    },
  ];

  // If there are custom columns, add custom data for those columns
  if (customColumns.length) {
    exampleData.forEach((data) => {
      customColumns.forEach((column) => {
        data[column.name] = "custom data";
      });
    });
  }

  return exampleData;
};

export default ColumnVisibility;
