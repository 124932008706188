import { useContext, useMemo, useState } from "react";
import { useDebouncedValue } from "@mantine/hooks";
import { AuthContext } from "../services/context";
import { getCurrentWorkspace } from "../services/userConfig";
import { useGetAllSpeakers } from "../api/speakers";

import Table from "../components/Speakers/Table";
import { useSetActionButtons } from "../services/ActionButtonsContext";
import { IconFileImport, IconPlus } from "@tabler/icons-react";

function Speakers() {
  const actions = useMemo(
    () => [
      {
        label: "Import",
        icon: <IconFileImport />,
        navigate: "./speakers/import",
      },
      {
        label: "Create",
        icon: <IconPlus />,
        navigate: "./speakers/create",
      },
    ],
    []
  );

  useSetActionButtons(actions);

  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);
  const currentEventId = currentWorkspace.eventId;

  const [pagination, setPagination] = useState({ page: 1, limit: 10 });

  const [sort, setSort] = useState({
    columnAccessor: "firstName",
    direction: "asc",
  });

  const [filter, setFilter] = useState(null);
  const [debouncedFilter] = useDebouncedValue(filter, 200);

  const { data, isPending } = useGetAllSpeakers({
    eventId: currentEventId,
    ...pagination,
    sort,
    filter: debouncedFilter,
  });

  return (
    <Table
      data={data}
      isPending={isPending}
      pagination={{ data: pagination, setPagination }}
      sort={sort}
      setSort={setSort}
      filter={{ data: filter, setFilter }}
    />
  );
}

export default Speakers;
