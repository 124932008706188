import { ajvResolver } from "@hookform/resolvers/ajv";
import { Button, Container, Paper, Title } from "@mantine/core";
import { fullFormats } from "ajv-formats/dist/formats";
import { useForm } from "react-hook-form";
import { Checkbox, Select, TextInput } from "react-hook-form-mantine";
import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
import { useCreateUser } from "../../api/user";

function CreateUser() {
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      role: "",
    },
    // TODO: Add validation schemas as shared package
    resolver: ajvResolver(
      {
        type: "object",
        properties: {
          firstName: { type: "string", minLength: 1 },
          lastName: { type: "string", minLength: 1 },
          email: { type: "string", format: "email", minLength: 1 },
          role: { type: "string", enum: ["sysAdmin", "wsAdmin", "member"] },
          config: {
            type: "object",
            properties: {
              isTicketAdmin: { type: "boolean" },
            },
          },
        },
        required: ["firstName", "lastName", "email", "role"],
      },
      { formats: fullFormats }
    ),
  });

  const { mutate, isPending, isSuccess, data } = useCreateUser();

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(`/app/users/${data.userId}`);
    }
  }, [isSuccess, data, navigate]);

  const selectedRole = watch("role");

  return (
    <Container size={500}>
      <Title>Create user</Title>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(mutate)}>
          <TextInput
            label="Name"
            placeholder="Name"
            type="text"
            name="firstName"
            control={control}
            withAsterisk
            mt="md"
          />
          <TextInput
            label="Last name"
            placeholder="Last name"
            type="text"
            name="lastName"
            control={control}
            withAsterisk
            mt="md"
          />
          <TextInput
            label="Email"
            placeholder="Email"
            name="email"
            control={control}
            withAsterisk
            mt="md"
          />
          <Select
            label="Role"
            placeholder="Role"
            name="role"
            withAsterisk
            control={control}
            data={[
              { label: "System administrator", value: "sysAdmin" },
              { label: "Workspace administrator", value: "wsAdmin" },
              { label: "Member", value: "member" },
            ]}
            mt="md"
          />

          {selectedRole === "wsAdmin" && (
            <Checkbox
              label="Ticket admin"
              description="This user will have only access to tickets"
              name="config.isTicketAdmin"
              control={control}
              mt="md"
            />
          )}
          <Button type="submit" mt="md" loading={isPending}>
            Create user
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default CreateUser;
