import { Center, Container } from "@mantine/core";
import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { AuthContext } from "../services/context";
import EditProfile from "./EditProfile";
import Logo from "./Logo";
import { getCurrentWorkspace } from "../services/userConfig";

export default function GuardRoute() {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const currentWorkspaceId = getCurrentWorkspace(user);

  const wsAdminWithNoAssignedWorkspace =
    user?.role === "wsAdmin" && !currentWorkspaceId;

  if (!user) {
    return (
      <Navigate
        to="/"
        state={{ returnTo: location.pathname + location.search }}
      />
    );
  }

  if (user.role === "member" || wsAdminWithNoAssignedWorkspace) {
    return (
      <Container>
        <Center>
          <Logo style={{ objectFit: "contain" }} />
        </Center>
        <EditProfile />
      </Container>
    );
  }

  return <Outlet />;
}
