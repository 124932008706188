import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import posthog from "posthog-js";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  RouterProvider,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { webConfig } from "../config.js";
import App from "./App.jsx";
import { AuthProvider } from "./services/context.jsx";
import { queryClient } from "./services/react-query.js";
import { ActionButtonsProvider } from "./services/ActionButtonsContext.jsx";

// Skip Sentry and Posthog initialization in development mode
if (!webConfig.isLocal) {
  Sentry.init({
    dsn: webConfig.sentry.dsn,
    environment: webConfig.isProduction ? "production" : "development",
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions

    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost"],

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  posthog.init(webConfig.posthog.key, {
    api_host: webConfig.posthog.host,
    autocapture: true,
    enable_heatmaps: true,
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
      },
    },
  });
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={webConfig.OAuth.webClientId}>
      <QueryClientProvider client={queryClient}>
        <MantineProvider withGlobalStyles withNormalizeCSS>
          <Notifications />
          <ModalsProvider>
            <AuthProvider>
              <ActionButtonsProvider>
                <RouterProvider router={App} />
              </ActionButtonsProvider>
            </AuthProvider>
          </ModalsProvider>
        </MantineProvider>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
