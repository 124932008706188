import { useContext, useState } from "react";
import {
  Avatar,
  Combobox,
  Flex,
  Text,
  InputBase,
  useCombobox,
  Loader,
} from "@mantine/core";
import { useGetAllTickets } from "../api/tickets";
import { getCurrentWorkspace } from "../services/userConfig";
import { AuthContext } from "../services/context";

export function SearchTicketHolders({ onValueChange }) {
  const [search, setSearch] = useState("");
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch("");
    },

    onDropdownOpen: () => {
      combobox.focusSearchInput();
    },
  });
  const { user: currentUser } = useContext(AuthContext);
  const currentEventId = getCurrentWorkspace(currentUser).eventId;

  const { data, isPending } = useGetAllTickets({
    eventId: currentEventId,
    searchQuery: search,
    limit: 5,
    page: 1,
  });

  const [value, setValue] = useState(null);

  const options = data?.data.map((item) => (
    <Combobox.Option value={item.userId} key={item.id}>
      <Flex align="center" gap="sm">
        <Avatar src={item?.picture} alt={item?.firstName}>
          {item?.firstName[0]} {item?.lastName[0]}
        </Avatar>
        <Flex direction="column">
          <Text size="sm">
            {item?.firstName} {item?.lastName}
          </Text>
          <Text c="dimmed" size="xs">
            {item?.email} • {item?.occupation}
          </Text>
        </Flex>
      </Flex>
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        setValue(val);
        setSearch(val);
        onValueChange(val);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          rightSection={<Combobox.Chevron />}
          value={search}
          onChange={(event) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            setSearch(value || "");
          }}
          placeholder="Search value"
          rightSectionPointerEvents="none"
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {isPending ? (
            <Combobox.Empty>
              <Loader size="sm" />
              <Text>Loading...</Text>
            </Combobox.Empty>
          ) : options?.length > 0 ? (
            options
          ) : (
            <Combobox.Empty>No results found</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
