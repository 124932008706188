import { ajvResolver } from "@hookform/resolvers/ajv";
import {
  Button,
  Container,
  Fieldset,
  Flex,
  Paper,
  TextInput as TextInputMantine,
} from "@mantine/core";
import { IconPlus, IconX } from "@tabler/icons-react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  MultiSelect,
  Select,
  TextInput,
  Textarea,
} from "react-hook-form-mantine";
import { usePatchSponsor } from "../../../api/sponsors";
import ImageUpload from "../../ImageUpload";
import { extractDirtyData } from "../../../utils/dirtyData";

function EditForm({ data, levelsData, sponsorReps, ticketsData }) {
  const { id } = data;

  const { mutate, isPending: isLoading, isSuccess } = usePatchSponsor(id);

  const levelOptions = levelsData.map((level) => ({
    value: level.id.toString(),
    label: level.name,
  }));

  const defaultLevel = levelOptions.find(
    (option) => option.value === data.levelId.toString()
  );

  // Options for representatives is in ticketsData
  const repOptions = ticketsData?.map((ticket) => {
    const fullName = `${ticket.firstName} ${ticket.lastName}`;
    const occupation = ticket.occupation ? ` , ${ticket.occupation}` : "";

    return {
      value: ticket.id.toString(),
      label: `${fullName}${occupation}`,
    };
  });

  const defaultReps =
    sponsorReps?.map((rep) => {
      const fullName = `${rep.firstName} ${rep.lastName}`;
      const occupation = rep.occupation ? ` , ${rep.occupation}` : "";

      return {
        value: rep.id.toString(),
        label: `${fullName}${occupation}`,
      };
    }) || [];

  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues: {
      name: data.name || "",
      levelId: defaultLevel?.value || "",
      description: data.description || "",
      logo: data.logo || "",
      links: data.links || [],
      repIds: defaultReps.map((rep) => rep.label),
    },
    resolver: ajvResolver({
      type: "object",
      properties: {
        name: { type: "string", minLength: 3 },
      },
    }),
  });

  const { fields, append, remove } = useFieldArray({
    name: "links",
    control,
  });

  const onSubmit = (data) => {
    const dirtyData = extractDirtyData(data, dirtyFields);

    if (dirtyData.repIds) {
      dirtyData.repIds = dirtyData.repIds.map((label) => {
        const selectedRep = repOptions.find(
          (option) => option.label === label || option.value === label
        );
        return selectedRep.value;
      });
    }
    mutate(dirtyData);
  };

  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Name"
            placeholder="Name"
            type="text"
            name="name"
            control={control}
            withAsterisk
            mt="md"
          />
          <Select
            label="Sponsor level"
            placeholder="Select sponsor level"
            name="levelId"
            data={levelOptions}
            control={control}
            mt="md"
            withAsterisk
          />
          <Textarea
            label="Description"
            placeholder="Description"
            name="description"
            control={control}
            mt="md"
            autosize
            minRows={4}
            maxRows={8}
          />

          <ImageUpload
            control={control}
            name="logo"
            label="Logo"
            description="Upload a logo for the sponsor"
            defaultImage={data.logo}
            mt="md"
            resize
          />

          <Fieldset legend="Links" mt="md">
            {fields.map((field, index) => (
              <Fieldset mt="md" key={field.id}>
                <TextInputMantine
                  label="Link label"
                  {...register(`links.${index}.label`)}
                />
                <TextInputMantine
                  label="Link URL"
                  {...register(`links.${index}.url`)}
                />

                <Button
                  mt={5}
                  size="xs"
                  style={{ backgroundColor: "red" }}
                  onClick={() => remove(index)}
                  leftSection={<IconX size={20} />}
                >
                  Remove link
                </Button>
              </Fieldset>
            ))}
            <Button
              mt={5}
              size="xs"
              style={{ justifySelf: "flex-end" }}
              onClick={() => append()}
              leftSection={<IconPlus size={20} />}
            >
              Add link
            </Button>
          </Fieldset>

          <MultiSelect
            label="Representative"
            name="repIds"
            data={repOptions}
            control={control}
            placeholder={
              defaultReps.length ? "" : "Select sponsor representative"
            }
            mt="md"
            description={
              repOptions.length === 0
                ? "No representatives available. Please create tickets for this event."
                : ""
            }
            searchable
          />

          <Flex justify="space-between">
            <Button
              type="submit"
              mt="md"
              loading={isLoading}
              disabled={!isDirty || isSuccess}
            >
              Edit sponsor
            </Button>
          </Flex>
        </form>
      </Paper>
    </Container>
  );
}

export default EditForm;
