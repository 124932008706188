import { Badge } from "@mantine/core";
import {
  IconCircleCheck,
  IconClock,
  IconXboxXFilled,
} from "@tabler/icons-react";
import React from "react";

function RegistrationStatusBadge({ status }) {
  const statusColors = {
    approved: "teal",
    pending: "orange",
    rejected: "red",
  };

  const leftSection = {
    approved: <IconCircleCheck size={12} />,
    pending: <IconClock size={12} />,
    rejected: <IconXboxXFilled size={12} />,
  };

  return (
    <Badge
      color={statusColors[status]}
      style={{ textTransform: "capitalize" }}
      leftSection={leftSection[status]}
      variant="light"
    >
      {status}
    </Badge>
  );
}

export default RegistrationStatusBadge;
